<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!resellerProduct">
      <span class="loader"></span>
    </span>
    <reseller-product-form
      v-if="resellerProduct"
      :loading="loading"
      :resellerProductData="resellerProduct"
      :formErrors="formErrors"
      @resellerProductSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import ResellerProductForm from "../partials/ResellerProductForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  layout: "DashboardLayout",

  components: { ResellerProductForm },

  mixins: [alertLeave, requestErrorMixin],

  props: {
    resellerProductId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      resellerProduct: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "resellerProducts/get",
          this.resellerProductId
        );
        this.resellerProduct =
          this.$store.getters["resellerProducts/resellerProduct"];
        this.loading = false;
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async handleSubmit(resellerProduct) {
      this.loading = true;
      const resellerProductData = cloneDeep(resellerProduct);

      try {
        await this.$store.dispatch(
          "resellerProducts/update",
          resellerProductData
        );
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("RESELLER_PRODUCTS.RESELLER_PRODUCT_UPDATED"),
        });
        const resellerProduct = await this.$store.getters[
          "resellerProducts/resellerProduct"
        ];
        this.$emit("onViewResellerProduct", resellerProduct, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
